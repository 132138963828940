@font-face {
  font-family: 'Roboto Serif';
  src: local('./fonts/RobotoSerif-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal, italic;
}

@font-face {
  font-family: 'Roboto Serif';
  src: local('./fonts/RobotoSerif-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal, italic;
}

@font-face {
  font-family: 'Italianno';
  src: url('./Italianno-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ephesis';
  src: url('./Ephesis-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant Garamond';
  src: url('./CormorantGaramond-VariableFont_wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* #root, body, html{
  width: 100%;
  min-width: 100vw;
  margin: 0;
  padding: 0;
} */

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output,
ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

* {
  margin: 0;
  padding: 0;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  /* this is to avoid the autofill background flash */
  -webkit-text-fill-color: black !important;
  /* adjusts the text color */
  background-color: #4664A1 !important;
  /* your desired background color */
}


.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
  z-index: 0;
}


/* .container-fluid, .row {
  padding: 0;
  margin: 0;
  width: 100%;
} */

.site-content {
  flex: 1;
}

.App {
  text-align: center;
}

/* body {
  margin: 0;
  padding: 0;
  color: #101010; 
} */

.header {
  font-family: "Roboto Serif", serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 1.5rem 0rem 1.25rem;
  background: transparent;
  color: rgb(46, 89, 166);
}

.header-container {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url(Header_3.png);
  background-position: center;
  background-size: cover;
  padding-top: 1rem 0;
  /* margin-bottom: 1rem; */
  /* height: 11rem; */
  overflow: visible;
  height: 10rem;
}

.header-background {
  background-image: url(bannerBackground.png);
  background-position: 50% 50%;
  background-size: cover;
  max-height: 13rem;
  position: relative;
  display: flex;
  justify-content: center;
}

.full-logo {
  max-height: 7rem;
  object-fit: contain;
}

/* .navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; 
  margin-top: 6.25rem;
  font-family: 'Roboto Serif', serif;
  white-space: nowrap;
} */

.navbar {
  margin: 0;
  padding: 0;
  background: transparent;
  overflow: visible;
  /* z-index: 1100; */
}

/* .navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
} */

.navbar ul {
  z-index: 3000;
  position: relative;
}

.navbar li {
  margin: 0 2rem;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
  font-size: 1.5rem;
  z-index: 2000;
}

.navbar li:hover {
  transform: scale(1.15);
}

.navbar a {
  text-decoration: none;
  color: rgb(46, 89, 166);
  /* font-family: "Roboto Serif", serif; */
  font-family: 'Cormorant Garamond', serif;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  text-shadow:
    -.006rem -.006rem 0 white,
    .006rem -.006rem 0 white,
    -.006rem .006rem 0 white,
    .006rem .006rem 0 white;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.navbar a:hover {
  transform: scale(0.9);
  /* font-weight: lighter; */
  font-weight: 700;
  z-index: 1001;
}

.navbar-nav .nav-item {
  margin-left: auto;
  margin-right: 1rem;
  font-size: 1.5rem;
  white-space: nowrap;
}

.nav-item.dropdown {
  position: relative;
}

.nav-link {
  color: rgb(46, 89, 166);
  /* font-family: "Roboto Serif", serif; */
  font-family: "Cormorant Garamond", serif;
  font-size: 1.25rem;
  text-shadow: -.006rem -.006rem 0 white, .006rem -.006rem 0 white, -.006rem .006rem 0 white, .006rem .006rem 0 white;
}

.nav-link:hover {
  color: #305799;
  transition: color 0.3s;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  border: 0.06rem solid #dcdcdc;
  padding-left: 0;
  padding-right: 0;
  max-height: 60vh;
  overflow-y: visible;
  margin-top: 0;
  z-index: 9999;
}

.dropdown-menu li a {
  font-size: 1.4rem;
  text-align: left;
  padding: 0;
}

.navbar-collapse {
  max-height: 80vh;
}

.collapse {
  max-height: 80vh;
}

/* Show dropdown on hover for desktop view */
@media (min-width: 992px) {
  .nav-item.dropdown:hover .dropdown-menu {
    display: block !important;
  }

  .service-row:hover .service-name,
  .service-row:hover .service-price {
    /* font-size: 1.525rem; */
    /* color: rgb(13, 110, 253) !important; */
    background-color: rgba(13, 110, 253, 0.4) !important;
  }

}

@media (max-width: 810px) {
  .client-file-deadline-date {
    flex-shrink: 0;
    width: auto;
    max-width: 6rem;
    margin-right: 1rem;
  }

  .client-file-deadline {
    flex-direction: column;
  }

  .document-viewer-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Darker background for emphasis */
    z-index: 1050;
    /* Higher than the client file modal */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .document-viewer-modal {
    background-color: #fff;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .client-file-file-container li {
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
  }

  .client-file-modal .nav-tabs .nav-link {
    font-size: 1rem !important;
  }

  .client-file-modal li {
    font-size: 1rem !important;
  }

  .client-file-modal {
    font-size: 1rem !important;
  }

  .client-file-item strong {
    width: 175px !important;
    margin-right: 0.25rem !important;
  }

  .client-portal_results-table {
    font-size: 1rem !important;
    word-wrap: break-word;
  }

  .client-portal_results-table th,
  .client-portal_results-table td {
    padding: 0.25rem 0.5rem !important;
    white-space: normal !important;
    overflow-wrap: break-word;
  }

  .client-portal_results-table th {
    text-align: center;
  }

  .client-portal_results-table th.uspto-link,
  .client-portal_results-table td.uspto-link {
    width: 100%;
    white-space: nowrap !important;
  }

  .header-container {
    position: relative;
    height: 8rem;
  }

  .full-logo {
    height: 4rem;
    object-fit: contain;
  }

  .navbar-toggler {
    position: absolute;
    /* left: 2rem;
                top: 1rem; */
    z-index: 1200;
    color: black;
    border: 0.025rem solid black;
  }

  .navbar-toggler-icon {
    background-color: transparent;
  }

  .navbar-brand {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1100;
  }

  .navbar-collapse {
    max-height: 70vh;
    overflow-y: visible;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1100;
    left: 1rem;
    top: 2rem;
    z-index: 1200;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    border: 0.0125rem solid #ddd;
    width: max-content;
  }

  .navbar {
    background: white;
  }

  .navbar-nav .nav-link {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
  }

  .nav-link ul {
    width: 100%;
  }

  .navbar-nav .dropdown-menu {
    position: absolute !important;
    left: -250% !important;
    top: 0 !important;
    padding: 0 !important;
    display: none !important;
    background-color: white !important;
    border: 1px solid #ddd !important;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2) !important;
    z-index: 99999 !important;
  }

  .nav-item.dropdown .dropdown-menu.show {
    display: block !important;
  }

  .dropdown-menu .dropdown-item {
    white-space: nowrap;
    width: 12rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0.5rem;
  }



  .dropdown-menu li a {
    font-size: 0.8rem;
    text-align: right;
    left: -10% !important;
    width: 100%;
  }

  .col-12 {
    width: 100%;
  }

  .accordion-container {
    width: 100%;
  }

  .accordion-item {
    font-size: 1rem;
    width: 100%;
  }

  .accordion-question {
    width: 100%;
    font-size: 1rem !important;
  }

  .accordion-container {
    font-size: 1rem !important;
  }

  .accordion-header {
    font-size: 1rem !important;
  }

  .accordion-answer {
    font-size: 1rem !important;
    z-index: 9999;
    position: relative;
  }

  .accordion-answer p {
    font-size: 1rem !important;
    z-index: 5000;
  }

  .accordion-collapse {
    font-size: 1rem !important;
  }

  .service-block h3 {
    font-size: 1.2rem !important;
    line-height: 1.0 !important;
  }

  .service-block p {
    font-size: 1rem !important;
  }

  .comp-name i {
    font-size: 8vw !important;
    font-weight: bold;
    line-height: 1 !important;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .comp-name p {
    font-size: 1.2rem !important;
  }

  .comp-descrip p {
    font-family: 'Italianno', italic;
    font-size: 1.2rem;
  }

  .name-group label {
    padding-bottom: 0;
    font-size: 0.9rem;
  }

  .appType-container label {
    font-family: 'Roboto Serif', serif;
    font-size: 0.9rem !important;
    text-align: left;
  }

  .description-cell {
    font-size: 1.2rem !important;
    max-width: 12rem;
  }

  .btn {
    padding: .375rem .75rem;
  }

  .form-label,
  .btn {
    font-size: 12px;
  }

  .label-container {
    font-size: 1.2rem !important;
    width: 12rem !important;
  }

  .label-container label {
    font-size: 1.2rem !important;
  }

  .field-container {
    font-size: 1.2rem !important;
  }

  .search-results-table {
    font-size: 1.2rem !important;
  }

  .search-results-table button {
    font-size: .1rem !important;
  }

  /* .table-striped td {
    font-size: 1rem !important;
  } */

}

/* Small Screen Adjustments */
@media (max-width: 576px) {

  .client-file-modal-container {
    max-width: 100vw;
    max-height: 100vh;
    font-size: 0.7rem;
  }

  .client-file-modal {
    font-family: 'Roboto Serif', serif;
    font-size: 0.7rem !important;

    max-width: 90vw;
    max-height: 90vh;
  }

  .client-portal_results-table {
    font-size: 0.75rem;
    width: 100%;
    max-width: 100%;
  }

  .header-container {
    position: relative;
    height: 8rem;
    z-index: 9 !important;
  }

  .full-logo {
    height: 4rem;
    object-fit: contain;
  }

  .navbar {
    z-index: 10 !important;
  }

  .navbar-toggler {
    position: absolute;
    /* left: 2rem;
      top: 1rem; */
    z-index: 1200;
    color: black;
    border: 0.025rem solid black;
  }

  .navbar-toggler-icon {
    background-color: transparent;
  }

  .navbar-brand {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1100;
  }

  .navbar-collapse {
    max-height: 70vh;
    overflow-y: visible;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1100;
    left: 1rem;
    top: 2rem;
    z-index: 1200;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    border: 0.0125rem solid #ddd;
    width: max-content;
  }

  /* Navbar container should establish a stacking context */
  .navbar {
    position: relative !important;
    z-index: 10 !important;
    background-color: white !important;
    /* Helps with bleed-through */
  }

  /* Make the dropdown higher than all siblings */
  .dropdown-menu.show {
    position: absolute !important;
    z-index: 99999 !important;
    background-color: white !important;
    border: 1px solid #ccc !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25) !important;
    padding: 1rem !important;
    top: 100% !important;
    right: 0 !important;
    display: block !important;
    opacity: 1 !important;
    pointer-events: auto !important;
  }

  .dropdown-menu.show li a {
    text-align: left;
    font-size: 0.9rem;
    line-height: 175%;
  }

  .navbar-nav .nav-item {
    position: relative;
    z-index: 1;
  }

  .navbar-nav .nav-item.dropdown {
    z-index: 1000;
    /* higher than others */
  }

  /* .navbar-nav.dropdown-open {
    z-index: 100 !important;
    pointer-events: none !important;
  } */

  .accordion-container {
    font-size: 0.8rem !important;
  }

  .accordion-header {
    font-size: 0.8rem !important;
  }

  .accordion-answer {
    font-size: 0.8rem !important;
    z-index: 9999;
    position: relative;
  }

  .accordion-answer p {
    font-size: 0.8rem !important;
    z-index: 5000;
  }

  .accordion-question {
    font-size: 0.8rem !important;
  }

  .accordion-collapse {
    font-size: 0.8rem !important;
  }

  .name-group label {
    padding-bottom: 0;
    font-size: 0.9rem;
  }

  .appType-container label {
    font-family: 'Roboto Serif', serif;
    font-size: 1rem;
    text-align: left;
  }

  .form-control {
    font-size: 0.9rem !important;
  }

  .description-cell {
    font-size: 1rem !important;
    max-width: 15rem !important;
  }

  .table th {
    font-size: 1rem !important;
    font-weight: normal;
  }

  .btn {
    padding: .25rem .4rem;
  }

  .form-label,
  .btn {
    font-size: 0.8rem !important;
  }

  .label-container {
    font-size: 1rem !important;
    width: 8rem !important;
  }

  .label-container label {
    font-size: 1rem !important;
  }

  .field-container {
    font-size: 1rem !important;
  }

  .search-results-table {
    font-size: 1rem !important;
  }

  .search-results-table button {
    font-size: .75rem !important;
  }

  .search-box {
    width: 17rem;
  }

  .search-box-input {
    width: 17rem;
  }

  .urlInput {
    width: 20rem !important;
  }

  .use-label {
    font-size: 1rem;
  }

  .comp-descrip {
    font-size: 2rem !important;
  }

  .use-req-container ul {
    margin-left: -5rem;
  }

  .use-req-container ul li {
    margin-left: 1rem;
  }

  .tm-search-box {
    max-width: 15rem !important;
  }

  .client-portal_results-table th:nth-child(1),
  .client-portal_results-table td:nth-child(1),
  .client-portal_results-table th:nth-child(2),
  .client-portal_results-table td:nth-child(2),
  .client-portal_results-table th:nth-child(4),
  .client-portal_results-table td:nth-child(4),
  .client-portal_results-table th:nth-child(5),
  .client-portal_results-table td:nth-child(5),
  .client-portal_results-table th:nth-child(6),
  .client-portal_results-table td:nth-child(6) {
    display: none;
  }

  .intro-section {
    font-size: 1.1rem;
    font-family: 'Cormorant Garamond, serif';
    font-size: '0.8rem';
    margin-left: '12%';
    margin-right: '12%';
    margin-bottom: '3%';
    font-weight: '600';
  }

  .info-section img {
    height: 50px !important;
  }

  .intro-section-brief {
    font-size: 1.1rem !important;
    margin-bottom: 6% !important;
  }

  .intro-section-descrip-1 {
    font-size: 1.1rem;
    margin-bottom: 4% !important;
  }

  .intro-section-descrip-2 {
    font-size: 1.1rem;
    margin-bottom: 10% !important;
  }

  .form-button {
    /* background-color: rgb(46, 89, 166); */
    font-weight: 400;
    font-size: 1.5rem !important;
    border-radius: 1.2rem;
    height: 4rem !important;
    padding: 0 3rem;
    box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
    color: white;
    text-align: center;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    cursor: pointer;
    border: none;
    transition: transform 0.1s ease-in-out;
    transform: scale(1);
    /* -webkit-text-stroke: 0.75px rgb(46, 89, 166); */
  }

  .admin-items {
    margin-top: 2rem !important;
  }
  
  .admin-item-link {
    font-size: 0.8rem;
  }

  .reasons-why-us {
    width: 95% !important;
    justify-content: center;
    text-align: left;
    margin-top: 2rem;
  }

  .reasons-why-us ul{
    margin-left: 0 !important;
  }

  .reasons-why-us ul li {
    font-family: "Roboto Serif", serif;
    font-size: 1.1rem !important;
    line-height: 2;
    font-weight: 300;
    list-style: none;
    position: relative; /* Needed for absolute positioning of ::before */
    padding-left: 1.75rem !important; /* Add padding to make space for the checkmark */
  }

  .reasons-why-us ul li::before {
    content: "\2714";
    position: absolute;
    left: 0;
    top: 0.75rem; /* Slight vertical alignment tweak */
    color: #1e88e5;
    font-size: 1.2rem !important; /* Make it a bit larger to match text size */
    line-height: 1;
  }

}

.footer {
  background-color: rgb(46, 89, 166) !important;
  color: #ffffff;
  padding: 2rem 0;
  font-family: "Roboto Serif", serif;
  margin-top: auto;
}

.footer-logo {
  height: 4rem;
  object-fit: contain;
}

.footer-link {
  text-decoration: none;
  color: #ffffff;
  font-family: "Roboto Serif", serif;
  font-size: 1.25rem;
  font-weight: 400;
}

.footer-link:hover {
  color: #00AFEF;
  /* Adjust hover color as desired */
  transition: color 0.3s;
}

.footer-logo img {
  max-height: 6.25rem;
  width: auto;
}

.footer-logo p {
  margin-left: 1.25rem;
  font-size: 1rem;
}

.footer-logo a {
  color: #00AFEF;
  /* Your chosen color for links */
  text-decoration: none;
  /* No underline */
}

.navbar-footer {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  margin-top: -0.625rem;
  margin-left: -9.375rem;
}

.navbar-footer nav ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-footer nav ul li {
  padding: 0 0.625rem;
}

.navbar-footer nav ul li a {
  text-decoration: none;
  color: white;
  /* font-size: 24px; */
  font-size: 1.5rem;
}

.navbar-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  /* margin-top: 100px; */
  margin-top: 6.25rem;
  font-family: 'Roboto Serif', serif;
  color: white;
  white-space: nowrap;
}

.navbar-footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.navbar-footer li {
  /* margin: 0 35px; */
  margin: 0 2.2rem;
}

.navbar-footer a {
  text-decoration: none;
  color: white;
  font-family: "Roboto Serif", serif;
  /* font-size: 32px; */
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
}

main {
  text-align: left;
}

/* .container {
  width: 100%;
} */

/* .main-section, .main-section-alt {
  width: 100%;
  margin-left: calc(-50vw + 50%);
  overflow-x: hidden;
} */


.main-section {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  text-align: center;
  color: black;
  padding: 1.25rem;
  margin-top: 3.125rem;
  box-sizing: border-box;
  align-items: center;
  align-content: center;
  padding: 10px;
  font-weight: normal;
  line-height: 1.6;
}

.main-section-alt {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgb(46, 89, 166);
  width: 100%;
  text-align: center;
  padding: 1.25rem;
  /* margin-top: 3.125rem; */
  margin-bottom: 0 !important;
  color: white;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  font-weight: normal;
  line-height: 1.6;
}

.main-section-alt p {
  font-family: 'Roboto Serif', serif;
  /* font-size: 24px; */
  font-size: 1.5rem;
  font-weight: 200;
}

.main-section-alt a {
  color: cyan;
}

.main-section-alt span {
  /* margin-bottom: 50px; */
  margin-bottom: 3.125rem;
}

.main-section h2 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: 'Roboto Serif', serif;
}

.main-section span {
  margin-bottom: 50px;
}

.main-section p {
  /* font-size: 24px; */
  font-size: 1.5rem;
  font-family: 'Roboto Serif', serif;
  margin-left: 5%;
  margin-right: 5%;
}

.main-section-alt p {
  /* font-size: 24px; */
  font-size: 1.5rem;
  font-family: 'Roboto Serif', serif;
  margin-left: 5%;
  margin-right: 5%;
}

.main-section-alt h2 {
  margin-bottom: 0;
  margin-top: 0;
}

.main-section-alt h3 {
  /* margin-bottom: 15px; */
  /* font-size: 48px; */
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
  font-style: italic;
  /* font-family: 'Roboto Serif', serif; */
  font-family: 'Cormorant Garamond', serif;
  font-weight: normal;
}

.main-section h3 {
  /* margin-bottom: 15px; */
  /* font-size: 48px; */
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-top: 0;
  font-style: italic;
  font-weight: normal;
  /* font-family: 'Roboto Serif', serif; */
  font-family: 'Cormorant Garamond', serif;
}

.main-section ul,
li {
  /* font-size: 24px; */
  font-size: 1.5rem;
  list-style: none;
  align-items: center;
  align-content: center;
}

.main-section ol,
li {
  /* font-size: 24px; */
  font-size: 1.5rem;
  font-weight: normal;
  font-family: 'Roboto Serif', serif;
}

.main-section-alt ol li {
  font-size: 1.5rem;
  font-weight: normal;
  font-family: 'Roboto Serif', serif;
  margin-left: 2%;
  margin-right: 2%;
}

.comp {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  box-sizing: border-box;
  text-shadow: 0px 0px 0.06rem rgba(0, 0, 0, 0.1);
}

.comp-name {
  /* font-family: 'Roboto Serif', serif; */
  font-family: 'Cormorant Garamond', serif;
  font-size: 4rem;
  font-weight: 500;
  text-align: center;
  align-content: center;
  line-height: normal;
  box-sizing: border-box;
  text-shadow: 0px 0px 0.06rem rgba(0, 0, 0, 0.1);
  /* margin-top: 3rem; */
  margin-top: 0;
}

.comp-descrip {
  font-family: 'Italianno', italic;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  align-content: center;
  line-height: normal;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
}

.services-list {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  text-align: center;
  font-size: 1.7rem;
  margin-top: 3.125rem;
  padding: 1.25rem;
  color: black;
  box-sizing: border-box;
  align-items: center;
  align-content: center;
  font-weight: normal;
  line-height: 1.6;
  font-family: 'Roboto Serif', serif;
}

.services-list-alt {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgb(46, 89, 166);
  width: 100%;
  text-align: center;
  font-size: 1.7rem;
  margin-top: 3.125rem;
  padding: 1.25rem;
  color: white;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  font-weight: normal;
  line-height: 1.6;
  font-family: 'Roboto Serif', serif;
}

.services-list-alt a {
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.services-list-alt a:hover {
  transform: scale(1.1);
}

.services-list a {
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.services-list a:hover {
  transform: scale(1.1);
}

.services-list h3 {
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-top: 0;
  font-style: italic;
  font-weight: normal;
  font-family: 'Roboto Serif', serif;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.services-list h3:hover {
  transform: scale(1.1);
}

.services-list-alt h3 {
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-top: 0;
  font-style: italic;
  font-weight: normal;
  font-family: 'Roboto Serif', serif;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.services-list-alt h3:hover {
  transform: scale(1.1);
}

.services-descrip {
  display: flex;
  flex-wrap: wrap;
  gap: 1.9rem 9.4rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

}

.service-block {
  flex: 0 1 calc(40%);
  height: 7.9rem;
  width: 80%;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  padding: 1.25rem;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* font-family: 'Roboto Serif', serif; */
  font-family: 'Cormorant Garamond', serif;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 1;
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
  margin: 0 auto;
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
}

.service-block:hover {
  transform: scale(1.1);
}

.service-block a {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.service-block h3 {
  margin-top: 0;
  margin-bottom: 0.3rem;
  font-size: 1.9rem;
  /* font-weight: normal; */
  font-weight: 600;
  text-decoration: none;
  font-style: normal;
}

.service-block p {
  margin: 0;
  font-style: italic;
  font-size: 1.4rem;
  text-decoration: none;
}

.form-button {
  /* background-color: rgb(46, 89, 166); */
  background-color: rgb(46, 89, 255);
  border: 1px white;
  font-family: 'Roboto', serif;
  /* font-family: 'Cormorant Garamond', serif; */
  /* font-weight: normal; */
  /* font-weight: 600; */
  font-weight: 400;
  font-size: 1.8rem;
  /* border-radius: 0.3rem; */
  border-radius: 1rem;
  height: 5rem;
  /* padding: 0.625rem; */
  padding: 0 3rem;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  color: white;
  text-align: center;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
  /* -webkit-text-stroke: 0.75px rgb(46, 89, 166); */
}

.form-button:hover {
  background-color: rgb(80, 156, 255);
  color: white;
  transform: scale(1.2);
}

.about-section {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  padding: 1.25rem;
  margin-top: 3.125rem;
  padding: 0.625rem;
  color: black;
  box-sizing: border-box;
}

.about-section p {
  font-family: 'Roboto Serif', serif;
  font-size: 1.4rem;
  font-weight: 100;
  text-align: left;
  margin-top: 0;
  margin-left: 5%;
  margin-right: 5%;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-family: 'Roboto Serif', serif;
  font-size: 1rem;
  z-index: 100;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(46, 89, 166);
  min-width: 10rem;
  box-shadow: 0rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.2);
  z-index: 5000;
  font-family: 'Roboto Serif', serif;
  border: 2px solid cyan;

}

.dropdown-content a {
  color: white;
  padding: 0.625rem 0.3rem;
  font-size: 1.3rem;
  text-decoration: none;
  display: block;
  white-space: nowrap;
  text-align: left;
  font-family: 'Roboto Serif', serif;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.accordion-container {
  text-align: center;
  width: 100%;
  max-width: 60rem;
  margin: auto;
}

.accordion-item {
  margin-bottom: 0.625rem;
  padding: 0.625rem;
  margin-top: 1.25rem;
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  border: 0.06rem solid #ddd;
  font-family: 'Roboto Serif', serif;
  background-color: #c8cbd0;
}

.accordion-question {
  display: block;
  width: 100%;
  text-align: left;
  background: rgb(46, 89, 166);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.625rem;
  font-size: 1.4rem;
  color: white;
  font-family: 'Roboto Serif', serif;
}

.accordion-answer {
  padding: 0.625rem;
  border-top: 0.06rem solid #ddd;
  background: #fff;
  font-family: 'Roboto Serif', serif;
  text-align: left;
  display: block;
  z-index: 9999;
  position: relative;
  font-size: 1.4rem;
}

/* .accordion-answer p {
  text-indent: -6em;
  padding-left: 6em;
} */

.answer-container {
  display: flex;
  flex-direction: column;
}

.answer-component {
  display: flex;
  flex-direction: row;
}

.answer-component p {
  display: block;
  margin-left: 0.5rem;
}

.use-req-container {
  text-align: left;
}

.use-req-container ul {
  text-align: left;
}

.use-req-container ul li {
  margin-left: 8rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: white;
  display: block;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  text-align: center;
  color: black;
  font-family: 'Roboto Serif', serif;
  z-index: 9999;
}

.modal h1 {
  display: flex;
  flex-direction: column;
  /* margin-top: 25px;
  font-size: 32px; */
  margin-top: 1.6rem;
  font-size: 2rem;
  box-sizing: border-box;
  font-family: 'Roboto Serif', serif;
  color: black;
  margin-bottom: 0;
  font-weight: normal;
}

.modal h2 {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  box-sizing: border-box;
  font-family: 'Roboto Serif', serif;
  color: black;
  /* font-size: 16px; */
  font-size: 1rem;
  font-style: italic;
  font-weight: normal;
}

.sub-modal {
  width: 100%;
  height: 100%;
}

.sub-modal-head {
  width: 100%;
  height: 100%;
}

.modal-header {
  margin-bottom: 1%;
  margin-top: -3%;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  /* font-size: 14pt; */
  font-size: 1.17rem;
}

/* .form-content {
  position: relative;
  width: 100%;
  height: 100%;
} */

.form-content {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.form-image {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 50rem;
  margin-top: 1.6rem;
  height: auto;
  z-index: -1;
}

.owner-form-image {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 42.5rem;
  margin-top: 4.7rem;
  height: auto;
  transform: rotate(90deg);
  z-index: -1;
}

.appType-form-image {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 51.5rem;
  margin-top: 1.6rem;
  height: auto;
  z-index: -1;
  transform: scaleX(-1);
}

.submission-form-image {
  position: absolute;
  max-width: 43.75rem;
  height: auto;
  z-index: -1;
  top: 0;
  right: 0;
}

.form-background-container {
  /* background-color: rgba(0, 83, 238, 0.75); */
  background-color: rgb(46, 89, 166);
  width: 80%;
  justify-content: center;
  margin: auto;
  padding: 2rem;
  border-radius: 2rem;
  color: white;
}

.contactName-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: 'Roboto Serif', serif;
  font-size: 1.125rem;
  /* margin-left: auto; */
  margin-left: 0;
  margin-right: auto;
}

/* .name-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-input {
  border: 0;
  border-bottom: 0.06rem solid black;
  margin-top: 0.3rem;
  width: 18.75rem;
  font-size: 1.125rem;
  font-family: 'Roboto Serif', serif;
  text-align: center;
}

.name-input:focus {
  outline: none;
  border-bottom: 0.125rem solid lawngreen;
} */

.intake-header {
  background-color: rgba(214, 215, 224, 0.25);
  margin-bottom: 2rem;
}

.name-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 0;
}

.name-input {
  border: 0.06rem solid rgb(177, 174, 174);
  border-radius: 0.250rem;
  margin-top: 0;
  width: 18.75rem;
  font-size: 1.125rem;
  font-family: 'Roboto Serif', serif;
  text-align: center;
}

.name-input:focus {
  outline: none;
  border: 0.125rem solid lawngreen;
}

.name-group label {
  /* padding-bottom: 0.3rem; */
  padding-bottom: 0;
  /* font-size: 1.125rem; */
  font-size: 0.9rem;
}

.name-group label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* .entity-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: center;
  font-family: 'Roboto Serif', serif;
  gap: 1.6rem;
  font-size: 1.125rem;
  margin-left: 3.125rem;
  margin-top: 0.9rem;
  width: 57.5rem;
}

.entity-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.entity-group label {
  padding-bottom: 0.3rem !important;
  font-size: 1.125rem;
}

.entity-group input {
  border: 0;
  border-bottom: 0.06rem solid black;
  margin-top: 0.3rem;
  width: 57.5rem;
  font-size: 1.125rem;
  font-family: 'Roboto Serif', serif;
  text-align: center;
}

.entity-input {
  border: 0;
  border-bottom: 0.06rem solid black;
  margin-top: 0.3rem;
  width: 57.5rem;
  font-size: 1.125rem;
  font-family: 'Roboto Serif', serif;
  text-align: center;
} */

.entity-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: center;
  font-family: 'Roboto Serif', serif;
  gap: 1.6rem;
  font-size: 1.125rem;
  margin-left: 3.125rem;
  margin-top: 0;
  width: 57.5rem;
}

.entity-group {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 0;
}

.entity-group label {
  padding-bottom: 0;
  font-size: 0.9rem;
  text-align: left;
}

.entity-group input {
  border: 0.06rem solid rgb(177, 174, 174);
  border-radius: 0.250rem;
  margin-top: 0.3rem;
  width: 57.5rem;
  font-size: 1.125rem;
  font-family: 'Roboto Serif', serif;
  text-align: center;
}

.entity-input {
  border: 0;
  border-bottom: 0.06rem solid black;
  margin-top: 0.3rem;
  width: 57.5rem;
  font-size: 1.125rem;
  font-family: 'Roboto Serif', serif;
  text-align: center;
}

/* .address-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: 'Roboto Serif', serif;
  gap: 0.625rem;
  font-size: 1.125rem;
  margin-left: 3.125rem;
  margin-top: 0.9rem;
}

.address-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address-input {
  border: 0;
  font-family: 'Roboto Serif', serif;
  border-bottom: 0.06rem solid black;
  margin-top: 0.3rem;
  width: 57.5rem;
  font-size: 1.125rem;
  text-align: center;
}

.address-input:focus {
  outline: none;
  border-bottom: 0.125rem solid lawngreen;
}

.address-group label {
  padding-bottom: 0.3rem;
  font-size: 1.125rem;
}

.address-group label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
} */

/* .address-group, .name-group, .city-group {
  padding-right: 0;
  padding-left: 0;
} */


.address-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.address-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: 'Roboto Serif', serif;
  gap: 0.625rem;
  font-size: 1.125rem;
  /* margin-left: 0; */
  margin-left: auto;
  /* margin-top: 0.9rem; */
  margin-top: 0.2rem;
}

.address-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.address-input {
  border: 0.06rem solid rgb(177, 174, 174);
  border-radius: 0.250rem;
  font-family: 'Roboto Serif', serif;
  margin-top: 0;
  width: 57.5rem;
  font-size: 1.125rem;
  text-align: center;
}

.address-input:focus {
  outline: none;
  border: 0.125rem solid lawngreen;
}

.address-group label {
  padding-bottom: 0;
  /* font-size: 1.125rem; */
  font-size: 0.9rem;
  text-align: left;
}

.address-group label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}


/* .city-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: 'Roboto Serif', serif;
  gap: 0.625rem;
  font-size: 1.125rem;
  margin-left: 3.125rem;
  margin-top: 1.9rem;
}

.city-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.city-input {
  border: 0;
  font-family: 'Roboto Serif', serif;
  border-bottom: 0.06rem solid black;
  margin-top: 0.3rem;
  width: 17.5rem;
  font-size: 1.125rem;
  text-align: center;
}

.city-input:focus {
  outline: none;
  border-bottom: 0.125rem solid lawngreen;
}

.city-group label {
  padding-bottom: 0.3rem;
  font-size: 1.125rem;
}

.city-group label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.zip-input {
  border: 0;
  font-family: 'Roboto Serif', serif;
  border-bottom: 0.06rem solid black;
  margin-top: 0.3rem;
  width: 14.375rem;
  font-size: 1.125rem;
  text-align: center;
}

.zip-input:focus {
  outline: none;
  border-bottom: 0.125rem solid lawngreen;
}

.zip-group label {
  padding-bottom: 0.3rem;
  font-size: 1.125rem;
}

.zip-group label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
} */

.city-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: flex-end;
  font-family: 'Roboto Serif', serif;
  /* gap: 0.625rem; */
  font-size: 1.125rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.2rem;
}

.city-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 200px;
}

.city-group input,
.city-group select {
  width: 100%;
  box-sizing: border-box;
}

.city-input {
  border: 0.06rem solid rgb(177, 174, 174);
  border-radius: 0.250rem;
  font-family: 'Roboto Serif', serif;
  margin-top: 0;
  width: 17.5rem;
  font-size: 1.125rem;
  text-align: center;

}

.city-input:focus {
  outline: none;
  border: 0.125rem solid lawngreen;
}

.city-group label {
  padding-bottom: 0;
  /* font-size: 1.125rem; */
  font-size: 0.9rem;
}

.city-group label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.zip-input {
  border: 0.06rem solid rgb(177, 174, 174);
  border-radius: 0.250rem;
  font-family: 'Roboto Serif', serif;
  margin-top: 0.3rem;
  width: 14.375rem;
  font-size: 1.125rem;
  text-align: center;
}

.zip-input:focus {
  outline: none;
  border: 0.125rem solid lawngreen;
}

.zip-group label {
  padding-bottom: 0;
  font-size: 1.125rem;
}

.zip-group label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.company-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: 'Roboto Serif', serif;
  gap: 0.625rem;
  font-size: 1.125rem;
  margin-left: 3.125rem;
  margin-top: 3.125rem;
}

.company-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-input {
  border: 0;
  font-family: 'Roboto Serif', serif;
  /* border-bottom: 1px solid black;
  margin-top: 5px;
  width: 450px !important; 
  font-size: 18px; */
  border-bottom: 0.06rem solid black;
  margin-top: 0.3rem;
  width: 28.125rem !important;
  font-size: 1.125rem;
  text-align: center;
}

.company-input:focus {
  outline: none;
  /* border-bottom: 2px solid lawngreen; */
  border-bottom: 0.125rem solid lawngreen;
}

.company-group label {
  /* padding-bottom: 5px;
  font-size: 18px; */
  padding-bottom: 0.3rem;
  font-size: 1.125rem;
}

.company-group label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 99%;
  height: 99%;
  /* background-color: rgba(135, 206, 250, 1.0); */
  background-color: rgb(13, 110, 253);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border-radius: 3.125rem;
  text-align: center;
  color: black;
  font-family: 'Roboto Serif', serif;
  margin: auto;

}

.test-search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 99%;
  height: 99%;
  /* background-color: rgba(135, 206, 250, 1.0); */
  background-color: rgb(13, 110, 253);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border-radius: 3.125rem;
  text-align: center;
  color: black;
  font-family: 'Roboto Serif', serif;
  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;

}

.search-sub-modal {
  width: 100%;

}

.search-modal-header {
  margin-bottom: 1%;
  margin-top: -3%;
  display: flex;
  flex-direction: column;
  color: black;
  text-align: center;
  font-size: 1.17rem;
}

.search-modal-content {
  width: auto;
  max-width: 90%;
  margin: auto;
  padding: 1.25rem;
  border-radius: 0.5rem;

}

.search-form {
  justify-content: center;
  text-align: center;
  align-items: center;
  text-align: center;
}

.description-input-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  background-color: white;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  /* border-radius: 50px; */
  /* border: 5px solid cyan; */
  border-radius: 3.125rem;
  border: 0.3rem solid cyan;
  text-align: center;
  color: black;
  font-family: 'Roboto Serif', serif;
}

.description-input-form-content {
  width: 100%;
}

.description-input-line {
  margin-bottom: 1%;
}

/* .address-container .input-group input,
.address-container .input-group select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 60%;
} */

.address2-container {
  display: flex;
  flex-direction: row;
  color: black;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.address-container .input-group {
  margin-bottom: 1em;
}

.appType-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: white;
  text-align: center;
  font-family: 'Roboto Serif', serif;
  font-size: 1.5rem;
  font-weight: 100;
  /* padding-top: 0.125rem; */
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.appType-container label {
  font-family: 'Roboto Serif', serif;
  font-size: 1.2rem;
  text-align: left;
}

.col-12 {
  padding-left: 0;
}

.appType-input {
  display: flex;
  flex-direction: row;
  color: white;
  font-family: 'Roboto Serif', serif;
  font-size: 1.2rem;
  font-weight: 100;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}

.applicationType {
  padding: 0.3rem 0.625rem;
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1.3rem;
  margin-left: 0.625rem;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  background-color: white;
  font-family: 'Roboto Serif', serif;
}

.applicationType:hover {
  padding: 0.3rem 0.625rem;
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1.3rem;
  margin-left: 0.625rem;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  background-color: rgb(248, 245, 245);
  font-family: 'Roboto Serif', serif;
}

.wordMark {
  padding: 0.3rem 0.625rem;
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1.5rem;
  width: 100%;
  margin-left: 0.625rem;
  background-color: white;
  font-family: 'Roboto Serif', serif;
}

.markLiteralElement {
  padding: 0.3rem 0.625rem;
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  /* margin-left: 0.625rem; */
  background-color: white;
  font-family: 'Roboto Serif', serif;
  width: 100%;
}

.markColorClaim {
  padding: 0.3rem 0.625rem;
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  /* margin-left: 0.625rem; */
  background-color: white;
  font-family: 'Roboto Serif', serif;
  width: 100%;
}

.input-container {
  color: white;
  text-align: center;
  font-family: 'Roboto Serif', serif;
}

.company-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-family: 'Roboto Serif', serif;
  margin-bottom: 1%;
  justify-content: flex-start;
  margin-left: 20%;
}

.company-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  text-align: center;
  font-family: 'Roboto Serif', serif;
  align-items: center;
  margin-right: 1%;
}

.company-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Roboto Serif', serif;
  margin-bottom: 1%;
  margin-left: 2%;
  width: 80%;
}

.company-group input {
  margin-bottom: 0px;
  text-align: center;
  font-family: 'Roboto Serif', serif;
  /* font-size: 14pt;
  height: 25px; */
  font-size: 1.17rem;
  height: 1.6rem;
  color: black;
}

.company-group label {
  text-align: center;
  /* font-size: 9pt; */
  font-size: 0.75rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* margin-left: 10px; */
  margin-left: 0.625rem;
  font-family: 'Roboto Serif', serif;
  margin-bottom: 1%;
}

.input-group input {
  margin-bottom: 0px;
  text-align: center;
  font-family: 'Roboto Serif', serif;
  /* font-size: 14pt;
  height: 25px; */
  font-size: 1.17rem;
  height: 1.6rem;
  color: black;
}

.input-group label {
  text-align: center;
  /* font-size: 9pt; */
  font-size: 0.75rem;
}

.step-indicator {
  display: flex;
  justify-content: space-evenly;
  /* padding: 20px; */
  padding: 1.25rem;
  margin-bottom: 1rem;
}

.circle {
  /* width: 50px;
  height: 50px;
  font-size: 24px;
  margin: 5px;
  border: 2px solid lightgray; */
  width: 3.125rem;
  height: 3.125rem;
  font-size: 1.5rem;
  margin: 0.3rem;
  border: 0.125rem solid lightgray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Serif', serif;
}

.current {
  border-color: lawngreen;
}

.filled {
  background-color: lawngreen;
  border-color: lawngreen;
  color: black;
}

/* .price-table table th tr td {
  border: 0.06rem solid black;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  font-size: 1.5rem;
  border-collapse: collapse;
  align-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto Serif', serif;
  font-weight: lighter;
}

.price-table td:nth-child(1) {
  padding-right: 0.625rem;
} */

.price-table {
  font-family: 'Roboto Serif', serif;
  font-size: 1.4rem;
}

.price-table .category-title {
  font-size: 1.5rem;
  font-family: 'Roboto Serif', serif;
}

.price-table .d-flex {
  border-bottom: 1px solid #ddd;
}

.price-table .row.no-gutters {
  border-bottom: 1px solid #ddd;
}

.price-table .row.no-gutters:last-child {
  border-bottom: none;
}

.service-name {
  flex: 1;
  text-align: left;
  padding-right: 0;
}

.service-price {
  flex: 0 0 11rem;
  text-align: center;
}

.price-table .col-12 {
  display: flex;
}

.price-table .col-8,
.price-table .col-lg-9 {
  text-align: left;
}

.price-table .col-4,
.price-table .col-lg-3 {
  text-align: center;
}

.search-results-table {
  border: 0.125rem solid black;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-collapse: collapse;
  align-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  margin: 3%;
  background-color: white;
  scrollbar-width: auto;
  font-size: 1.4rem;
}

.search-results-table th,
td {
  border: 0.06rem solid blue;
  padding: 0.125rem;
}

.search-results-table th:nth-child(2) {
  white-space: nowrap;
}

.search-results-table td:nth-child(3) {
  /* padding-right: 10px; */
  padding-right: 0.625rem;
  text-align: left;
}

.search-results-table button {
  border: 0.06rem solid black;
  border-radius: 0.125rem;
  font-size: 1rem;
  margin: 0.125rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
}

.add-button {
  padding: 0.3rem 0.625rem;
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  background-color: white;
  color: black;
  cursor: pointer;
  transition: transform 0.1s ease, background-color 0.1s ease;
}

.add-button:hover {
  background-color: #0056b3;
  /* Darker shade for hover effect */
  color: white;
}

.add-button:active {
  transform: scale(0.95);
  /* Slightly scale down the button on click */
  background-color: #003580;
  /* Even darker shade for active/click effect */
}

.close {
  margin: 0.625rem;
  font-size: 3.125rem;
  cursor: pointer;
}

.tm-search-box {
  font-size: 1.3rem !important;
  font-family: 'Roboto Serif', serif;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem !important;
  width: 35rem !important;
  /* max-width: 35rem !important; */
  padding: 1.2rem !important;
  border-radius: 0.5rem !important;
}

.search-box-input {
  width: 35rem;
  max-width: 35rem;
}

.search-button {
  border: 0.06rem solid black;
  border-radius: 0.25rem !important;
  font-size: 1.3rem;
  margin: 0.125rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.search-button:hover {
  border: 0.06rem solid lawngreen;
  border-radius: 0.125rem;
  font-size: 1.3rem;
  margin: 0.125rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  color: black;
  cursor: pointer;
  transform: scale(1.1);
}

.highlighted-search-term {
  background-color: rgba(0, 255, 255, 0.55);
  font-weight: bold;
}

.scrollable-table-container {
  /* max-height: 550px;
  margin-top: 20px; */
  max-height: 34.5rem;
  margin-top: 1.25rem;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: blue gray;
}


.form-buttons {
  font-family: 'Roboto Serif', serif;
  color: black;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  border-radius: 0.3rem;
  font-size: 1.125rem;
  width: 7.8rem;
  margin: 0.625rem;
  gap: 0.625rem;
  padding: 0.3rem;
  border: 0.1rem solid black;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.form-buttons:hover {
  cursor: pointer;
  background-color: rgba(0, 89, 255, 1.0);
  color: white;
  border-color: rgba(0, 89, 255, 1.0);
  transform: scale(1.1);
}

.form-buttons:disabled {
  background-color: #cccccc;
  border: 1px solid #cccccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.next-button {
  font-family: 'Roboto Serif', serif;
  color: black;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  /* font-size: 18px;
  border-radius: 5px;
  width: 125px;
  margin: 10px;
  gap: 10px;
  padding: 5px;
  border: 1.5px solid black;
  box-shadow: 0 1px 1px 1px #00000040; */
  font-size: 1.125rem;
  border-radius: 0.3rem;
  width: 7.8rem;
  margin: 0.625rem;
  gap: 0.625rem;
  padding: 0.3rem;
  border: 0.1rem solid black;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.next-button:hover {
  cursor: pointer;
  background-color: rgba(124, 252, 0, 0.8);
  color: white;
  border-color: lawngreen;
  transform: scale(1.1);
}

.next-button-disabled {
  font-family: 'Roboto Serif', serif;
  color: black;
  background-color: gainsboro;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  /* border-radius: 5px;
  font-size: 18px;
  width: 125px;
  margin: 10px;
  gap: 10px;
  padding: 5px;
  border: 1.5px solid black;
  box-shadow: 0 1px 1px 1px #00000040; */
  border-radius: 0.3rem;
  font-size: 1.125rem;
  width: 7.8rem;
  margin: 0.625rem;
  gap: 0.625rem;
  padding: 0.3rem;
  border: 0.1rem solid black;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  cursor: not-allowed;
}

.cancel-button {
  font-family: 'Roboto Serif', serif;
  color: black;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  /* font-size: 18px;
  border-radius: 5px;
  width: 125px;
  margin: 10px;
  gap: 10px;
  padding: 5px;
  border: 1.5px solid black;
  box-shadow: 0 1px 1px 1px #00000040; */
  font-size: 1.125rem;
  border-radius: 0.3rem;
  width: 7.8rem;
  margin: 0.625rem;
  gap: 0.625rem;
  padding: 0.3rem;
  border: 0.1rem solid black;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.cancel-button:hover {
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.9);
  color: white;
  border-color: rgba(255, 0, 0, 0.9);
  transform: scale(1.1);
}

.stateCB {
  font-family: 'Gentium Plus', serif;
  background-color: white;
  color: black;
  text-align: left;
  width: 100%;
  /* border: 1px solid white;
  border-radius: 5px;
  font-size: 12pt;
  height: 25px; */
  border: 0.06rem solid white;
  border-radius: 0.3rem;
  font-size: 1rem;
  height: 1.6rem;
}

.countryCB {
  /* border: 1px solid white;
  border-radius: 5px;
  font-size: 12pt;
  height: 25px;
  box-shadow: 0 1px 1px 1px #00000040; */
  border: 0.06rem solid white;
  border-radius: 0.3rem;
  font-size: 1rem;
  height: 1.6rem;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  color: black;
  text-align: left;
  width: 100%;
}

.ownerSelection-container {
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.ownerSelection {
  /* border: 1px solid white;
  border-radius: 5px;
  font-size: 12pt;
  height: 25px; */
  border: 0.06rem solid white;
  border-radius: 0.3rem;
  font-size: 1rem;
  height: 1.6rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  color: black;
  text-align: left;
  width: 4rem;
  max-width: 4rem;
  min-width: 4rem;
}

.fileSelectButton {
  /* border: 1px solid black;
  border-radius: 5px;
  font-size: 14pt;
  margin-left: 25px;
  box-shadow: 0 1px 1px 1px #00000040; */
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  margin-left: 1.6rem;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.fileSelectButton:hover {
  /* border: 1px solid black;
  border-radius: 5px;
  font-size: 14pt;
  margin-left: 25px;
  box-shadow: 0 1px 1px 1px #00000040; */
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  margin-left: 1.6rem;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  font-family: 'Roboto Serif', serif;
  background-color: lawngreen;
  border-color: lawngreen;
  color: white;
  transform: scale(1.1);
}

.file-upload-size-limit {
  font-size: 1.1rem !important;
  color: white !important;
}

.soundDescription {
  /* padding: 5px 10px;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 12pt;
  margin-left: 10px; */
  padding: 0.3rem 0.625rem;
  border: 0.06rem solid white;
  border-radius: 0.3rem;
  font-size: 1rem;
  margin-left: 0.625rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  width: 30%;
}

.classNo {
  /* padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 12pt;
  margin-top: 5px; */
  padding: 0.625rem;
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1rem;
  margin-top: 0.3rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  box-sizing: border-box;
}

#goods-services {
  resize: vertical;
}

.goods-services {
  padding: 0.625rem;
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1rem;
  margin-top: 0.3rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  box-sizing: border-box;
}

.goods-services-input-box {
  font-size: 1rem;
  border: 0.06rem solid black;
  border-radius: 0.125rem;
  margin-left: 0.3rem;
  font-family: 'Roboto Serif', serif;
  max-width: 95%;
  box-sizing: border-box;
  color: black;
}

.core-goods-services-display-input-box {
  font-size: 1.3rem;
  border-radius: 0.125rem;
  margin-left: 0.3rem;
  font-family: 'Roboto Serif', serif;
  font-weight: normal;
  border: none;
  outline: none;
  /* width: 95%; */
  width: calc(80% - 8px);
  padding: 0;
  background-color: white;
  color: black;
  box-sizing: border-box;
  min-height: 30px;
  overflow-y: hidden;
  flex-grow: 0;
  flex-shrink: 1;

}

.core-goods-services-editable-input-box {
  font-size: 1.3rem;
  border-radius: 0.125rem;
  margin-left: 0.3rem;
  border: 0.06rem solid black;
  font-family: 'Roboto Serif', serif;
  outline: none;
  width: 95%;
  padding: 0;
}

.additional-input-gs-input-box {
  font-size: 1.3rem;
  border-radius: 0.125rem;
  border: 0.06rem solid black;
  padding: 0.06rem;
  margin-left: 0.125rem;
  font-family: 'Roboto Serif', serif;
  outline: none;
  /* width: 100%; */
  width: 20%;
  text-align: center;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 1;
}

.table td {
  padding-top: 0;
  padding-left: 0;
  border: none;
}

.table th {
  padding: 0;
  font-size: 1.3rem;
  font-weight: normal;
  border-bottom: none;
  position: relative;
}

.table th::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 45%;
  border-bottom: 0.01rem solid rgb(46, 89, 166);
  transform: translateX(-50%);
}

.table tr:first-child td {
  padding-top: 0.5rem !important;
}

.description-cell>div {
  display: flex;
  align-items: center;
  width: 100%;
}

.description-cell {
  font-size: 1.2rem !important;
  width: 50rem;
  /* max-width: 36rem; */
  /* width: 100%; */
}

.selected-descriptions-table {
  font-size: 1.3rem;
  /* width: 59rem; */
  width: auto;
  max-width: 90%;
  min-width: 50rem;
  font-family: 'Roboto Serif', serif;
  color: black;
  border: none;
  white-space: normal;
  word-wrap: break-word;
  background-color: white;
  table-layout: fixed;
  margin-left: auto;
  margin-right: auto;
}

.selected-descriptions-table td {
  border: none;
  text-align: left;
  font-size: 1.3rem;
  font-family: 'Roboto Serif', serif;
}

.selected-descriptions-table td:nth-child(1) {
  width: 89%;
}

.selected-descriptions-table td:nth-child(2) {
  width: 5.5%;
  text-align: center;
}

.selected-descriptions-table td:nth-child(2) {
  width: 5.5%;
  text-align: center;
}

.no-results {
  font-size: 2rem;
  color: red;
}

#addClassButton {
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  margin-top: 3%;
}

.class-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-family: 'Roboto Serif', serif;
  margin-bottom: 0.3rem;
  font-size: 1rem;
  text-align: center;
  /* margin-left: 15%; */
  /* margin-left: auto;
  margin-right: auto; */
  width: 100%;
}

.label-container {
  display: flex;
  text-align: right;
  font-family: 'Roboto Serif', serif;
  width: 11rem;
  font-size: 1.3rem;
}

.field-container {
  flex-grow: 1;
  align-items: flex-start;
  text-align: left;
  display: flex;
  font-family: 'Roboto Serif', serif;
  font-size: 1.3rem;
}

textarea {
  width: 80%;
  box-sizing: border-box;
  text-align: left;
  font-family: 'Roboto Serif', serif;
  padding: 0.625rem;
  border-radius: 0.3rem;
  border: 0.06rem solid black;
  margin-top: 0.3rem;
  font-size: 1rem;
}

.class-blocks-container {
  display: flex;
  flex-direction: column;
  margin: 2% auto;
  font-size: 1rem;
  font-family: 'Roboto Serif', serif;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.add-class {
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
}

.remove-class {
  border: 0.06rem solid black;
  border-radius: 0.2rem;
  font-size: 1rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
  margin-bottom: 0.75rem;
}

.remove-class:hover {
  transform: scale(1.2);
  background-color: red;
}

.useSelection {
  margin-bottom: 2%;
  text-align: left;
  font-size: 1.3rem;
  font-family: 'Roboto Serif', serif;
}

.first-use {
  border: 0.06rem solid black;
  border-radius: 0.3rem;
  padding: 0.3rem;
  font-size: 1.3rem;
  /* margin-left: 2%; */
  font-family: 'Roboto Serif', serif;
  background-color: white;
  width: 12rem;
}

.use-class-container {
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-start;
  margin-bottom: 2%;
  margin-left: 5%;
  padding: 0;
  /* gap: 10px; */
  gap: 0.625rem;
}

.first-use-container {
  display: flex;
  flex-direction: row;
  text-align: right;
  align-items: flex-start;
  margin-left: 10%;
  align-content: flex-start;
  width: 100%;
  /* font-size: 16pt; */
  font-size: 1.3rem;
  font-family: 'Roboto Serif', serif;
  justify-content: space-between;
}

.urlInput {
  text-align: center;
  align-items: start;
  align-content: center;
  font-family: 'Roboto Serif', serif;
  width: 40rem;
  border: 0.06rem solid black;
  font-size: 1.3rem;
}

.urlInput:focus {
  outline: none;
  /* border-bottom: 2px solid lawngreen; */
  border-bottom: 0.125rem solid lawngreen;
}

.first-use-label {
  display: flex;
  text-align: right;
  width: 18.75rem;
  margin-bottom: 1%;
}

.use-block {
  display: flex;
  flex-direction: row;
  /* font-size: 16pt; */
  font-size: 1.3rem;
  font-family: 'Roboto Serif', serif;
}

.international-class-label {
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* width: 175px; */
  width: 11rem;
}

.goodsServices-label {
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* width: 175px; */
  width: 11rem;
}

.intClass {
  text-align: left;
  margin-left: 1%;
  align-items: flex-start;
  /* width: 750px; */
  width: 47rem;
}

.gs {
  text-align: left;
  margin-left: 1%;
  align-items: flex-start;
  width: 62.5rem;
}

.specimens {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  /* gap: 20px;
  padding: 10px; */
  gap: 1.25rem;
  padding: 0.625rem;
}

.foreign-certificates {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  /* gap: 20px;
  padding: 10px; */
  gap: 1.25rem;
  padding: 0.625rem;
}

.specimen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: auto;
  /* border: 2px solid #fff; */
  border: 0.125rem solid #fff;
}

.certificate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: auto;
  /* border: 2px solid #fff; */
  border: 0.125rem solid #fff;
}

.thumbnail {
  /* width: 100px;
  height: 100px;
  margin-bottom: 5px; */
  width: 6.25rem;
  height: 6.25rem;
  margin-bottom: 0.3rem;
}

.remove-document {
  /* border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14pt;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 5px; */
  border: 0.06rem solid #ccc;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  padding: 0.3rem 0.625rem;
  margin-top: 0.625rem;
  margin-bottom: 0.3rem;
  font-family: 'Roboto Serif', serif;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.foreign-app {
  /* margin-left: 105px; */
  margin-left: 6.5rem;
}

.foreign-app-country-label {
  /* width: 550px; */
  width: 34rem;
  margin-bottom: 1%;
}

.foreign-app-no-label {
  display: flex;
  text-align: right;
  /* width: 450px; */
  width: 28.125rem;
  margin-bottom: 1%;
}

.foreignUseCountryCB {
  align-items: flex-start;
  text-align: right;
  align-content: flex-start;
  width: 18.75rem;
}

.foreign-app-no {
  text-align: center;
  align-items: flex-start;
  align-content: flex-start;
  font-family: 'Roboto Serif', serif;
  border: 0;
  /* border-bottom: 1px solid black;
  width: 300px;
  font-size: 16pt; */
  border-bottom: 0.06rem solid black;
  width: 18.75rem;
  font-size: 1.3rem;
}

.foreign-app-no:focus {
  outline: none;
  /* border-bottom: 2px solid lawngreen; */
  border-bottom: 0.125rem solid lawngreen;
}

.foreign-app-filing-date {
  text-align: center;
  align-items: flex-start;
  align-content: flex-start;
  font-family: 'Roboto Serif', serif;
  /* width: 175px;
  border-radius: 5px;
  font-size: 14pt; */
  width: 11rem;
  border-radius: 0.3rem;
  font-size: 1.2rem;
}

.retrieve-matter-button {
  background-color: rgb(46, 89, 166);
  font-family: 'Roboto Serif', serif;
  color: white;
  text-align: center;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  /* font-size: 16pt;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 10px; */
  font-size: 1.3rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 1.25rem;
  padding: 0.625rem;
}

.info-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Roboto Serif', serif;
  /* font-size: 15pt; */
  font-size: 1.25rem;
  margin: 0 auto;
}

.info-line {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: center;
  /* width: 100%; */
  /* margin: 0.3rem 0; */
  gap: 1rem;
}

.info-line label {
  text-align: right;
  /* margin-right: 15px;
  width: 270px; */
  /* margin-right: 1rem; */
  width: 25rem;
}


.info-line input,
.info-line span,
.info-line textarea {
  text-align: left;
}

.info-line div {
  flex-grow: 1;
}

.app-section-header {
  width: 100%;
  text-align: left;
  text-decoration: underline;
  /* margin: 20px 0;
  font-size: 18pt; */
  margin: 1.25rem 0;
  font-size: 1.4rem;
}

.des-logo {
  height: 6.25rem;
  width: 6.25rem;
  margin-top: 0.625rem;
  cursor: pointer;
}

.app-review-container {
  font-family: 'Roboto Serif', serif;
  font-size: 1.25rem;
  text-align: center;
  margin: auto;
}

.app-review-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}

.atty-review-button {
  width: 13rem;
  font-size: 1.3rem;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.atty-review-button:hover {
  transform: scale(1.1);
}

.atty-deny-button {
  background-color: red;
  border-color: red;
}

.atty-deny-button:hover {
  background-color: red;
  border-color: red;
}

.document-label {
  font-family: 'Roboto Serif', serif;
  font-size: 1.25rem;
  margin-bottom: 0.3rem;
}

.specimen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.specimens {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.specimens img {
  /* width: 100px;
  height: 100px; */
  width: 6.25rem;
  height: 6.25rem;
}

.enlarged-specimen {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 95%;
  height: 95%;
  margin: 1%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
}

.enlarged-specimen-content {
  max-width: 90%;
  max-height: 90%;
  display: block;
  width: auto;
  height: auto;
  object-fit: contain;
  justify-content: center;
  align-items: center;
}

.close-enlarged-specimen {
  position: absolute;
  font-weight: bold;
  transition: 0.3s;
  color: #f1f1f1;
  /* top: 15px;
  right: 35px;
  font-size: 40px; */
  top: 1rem;
  right: 2.2rem;
  font-size: 2.5rem;
}

.close-enlarged-specimen:hover,
.close-enlarged-specimen:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.app-review-class-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3.125rem;
}

.edit-app-button {
  font-family: 'Roboto Serif', serif;
  background-color: rgba(227, 230, 242, 0.2);
  /* font-size: 10pt;
  margin-left: 5px;
  border: 1px solid black;
  border-radius: 2px; */
  font-size: 0.8rem;
  margin-left: 0.3rem;
  border: 0.06rem solid black;
  border-radius: 0.125rem;
}

.submission-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -53%);
  width: 80%;
  height: 90%;
  background-color: white;
  z-index: 1000;
  display: block;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  text-align: center;
  color: black;
  font-family: 'Roboto Serif', serif;
  /* border: 5px solid rgb(46, 89, 166);
  box-shadow: 0 2px 2px 2px #00000040;
  border-radius: 50px; */
  border: 0.3rem solid rgb(46, 89, 166);
  box-shadow: 0 0.125rem 0.125rem 0.125rem #00000040;
  border-radius: 3.125rem;
}

.submission-form-container {
  display: flex;
  flex-direction: column;
}

.submission-form-content {
  width: 100%;
  align-items: flex-start;
  text-align: left;
  margin-top: 15%;
  /* font-size: 32pt; */
  font-size: 2.6rem;
  display: flex;
  flex-direction: row;
}

.submission-form-content ul {
  text-decoration: none;
  list-style: none;
  margin-left: 5%;
  width: 40%;
  align-items: flex-start;
}

.submission-form-content li {
  /* padding: 20px;
  font-size: 28pt; */
  padding: 1.25rem;
  font-size: 2.3rem;
  font-weight: lighter;
}

.loading {
  /* width: 40px;
  height: 40px; */
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}

.close-submit-button {
  /* border: 1px solid black;
  border-radius: 2px;
  font-size: 18pt;
  box-shadow: 0 1px 1px 1px #00000040;
  margin: 2px;
  padding: 5px 20px; */
  border: 0.06rem solid black;
  border-radius: 0.125rem;
  font-size: 1.4rem;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  margin: 0.125rem;
  padding: 0.3rem 1.25rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.close-submit-button:hover {
  /* border: 1px solid rgb(46, 89, 166);
  border-radius: 2px;
  font-size: 18pt;
  margin: 2px;
  padding: 5px 20px; */
  border: 0.06rem solid rgb(46, 89, 166);
  border-radius: 0.125rem;
  font-size: 1.4rem;
  margin: 0.125rem;
  padding: 0.3rem 1.25rem;
  font-family: 'Roboto Serif', serif;
  background-color: rgb(46, 89, 166);
  color: white;
  transform: scale(1.25);
}

textarea.core-description,
textarea.additional-input {
  width: auto;
  resize: none;
  font-family: 'Roboto Serif', serif;
  box-sizing: border-box;
  overflow-y: hidden;
  /* min-width: 50px;
  max-width: 950px;
  border: 1px solid black;
  font-size: 16pt;
  padding: 2px 6px; */
  min-width: 3.125rem;
  max-width: 60rem;
  border: 0.06rem solid black;
  font-size: 1.3rem;
  padding: 0.125rem 0.375rem;
}

textarea.additional-input {
  background-color: #f0f0f0;
}

textarea {
  resize: none;
}

.warning-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: auto;
  background-color: rgb(201, 60, 60);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  text-align: center;
  color: white;
  font-family: 'Roboto Serif', serif;
  box-sizing: border-box;
  padding: 1.25rem;
  border-radius: 0.625rem;
}

.warning-modal h2 {
  font-size: 2.6rem;
  color: white;
}

.warning-modal-content {
  max-width: 90%;
  margin-bottom: 1.25rem;
  background-color: rgb(201, 60, 60);
}

.warning-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 0.625rem;
}

.warning-modal-button {
  /* font-size: 14pt;
  border: 1px solid black;
  border-radius: 3px;
  padding: 10px 20px; */
  font-size: 1.2rem;
  border: 0.06rem solid black;
  border-radius: 0.2rem;
  padding: 0.625rem 1.25rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  color: black;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.warning-modal-button:hover {
  transform: scale(1.1);
}

.trademark-application {
  position: relative;
  /* Creates a positioning context */
  height: 50vh;
}

.trademark-review-block {
  position: absolute;
  width: 60%;
  max-height: 70%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  padding: 0.625rem;
  color: black;
  line-height: 1;
}

.trademark-info-block {
  line-height: 1;
  margin-top: 0.625rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.trademark-info-block span {
  font-size: 1.1rem;
}

.trademark-info-comp {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  width: 100%;
}

.trademark-info-label {
  width: 24rem;
  padding-right: 0.1rem;
}

.trademark-info-value {
  padding-left: 0.1rem;
  width: 100%;
}


.trademark-logo {
  width: 6.25rem;
  height: 6.25rem;
}

.trademark-owner-email-input {
  font-size: 1rem;
  font-family: 'Roboto Serif', serif;
  margin-left: 0.3rem;
  width: 31.25rem;
  border: 0;
  border-bottom: 0.06rem solid black;
}

.trademark-owner-email-input:focus {
  outline: none;
  border-bottom: 0.625rem solid lawngreen;
}

.engagement-letter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: auto;
  border-radius: 30px;
  text-align: left;
  color: black;
  font-family: 'Roboto Serif', serif;
  border: 0.3rem solid rgb(46, 89, 166);
  box-shadow: 0 0.125rem 0.125rem 0.125rem #00000040;
  padding: 1.25rem;
}

.engagement-letter-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: auto;
  border-radius: 1.875rem;
  text-align: left;
  color: black;
  font-family: 'Roboto Serif', serif;
  border: 0.3rem solid rgb(46, 89, 166);
  box-shadow: 0 0.125rem 0.125rem 0.125rem #00000040;
  padding: 1.25rem;
}

.engagement-letter-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.engagement-letter-header img {
  max-width: 12.5rem;
  height: auto;
  margin-bottom: 0.625rem;
}

.engagement-letter-header-contact {
  display: flex;
  font-size: 0.8rem;
  justify-content: space-evenly;
  width: 50%;
  padding: 0 1.25rem;
  color: rgb(46, 89, 166);
}

.engagement-letter-container h2 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 3.125rem;
  text-align: left;
  margin-left: 2%;
}

.engagement-letter-container p {
  font-size: 1.2rem;
  font-weight: normal;
  text-align: left;
  margin-left: 2%;
  margin-right: 2%;
}

.engagement-description {
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 5%;
  margin-right: 5%;
}

.engagement-description ol li {
  font-size: 1.2rem;
  font-weight: normal;
  list-style: decimal;
  line-height: 1.7;
}

.engagement-letter-container ol li {
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 2%;
  /* margin-right: 0; */
  width: 100%;
}

.engagement-signature-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 2%;
  gap: 1.25rem;
  width: 75%;
  margin-top: 1.6rem;
}

.acknowledgement {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.acknowledgement input {
  margin-right: 0.625rem;
}

.acknowledgement label {
  font-size: 1rem;
  margin: 0;
}

.engagement-signature-container input {
  text-align: left;
  font-size: 1.4rem;
  font-family: 'Lucida Handwriting', cursive;
}

.engagement-signature-container input::placeholder {
  text-align: left;
  font-size: 1.4rem;
  font-family: 'Roboto Serif', serif;
}

.signature span {
  font-size: 1.5rem;
  font-family: 'Roboto Serif', serif;
  margin-right: 0.3rem;
  font-style: italic;
}

.engagement-letter-body ol li {
  font-size: 1.2rem;
  font-weight: normal;
  list-style: decimal;
  line-height: 1.7;
}

.goodsService-help-span {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.goodsService-help-span:hover {
  color: lawngreen;
  font-size: 1.5rem;
}

.specimens-help-span {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.specimens-help-span:hover {
  color: lawngreen;
  font-size: 1.5rem;
}

.goodsService-help-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: auto;
  background-color: rgb(46, 89, 166);
  z-index: 1005;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border-radius: 0.625rem;
  text-align: center;
  color: white;
  font-family: 'Roboto Serif', serif;
  padding: 1.25rem;
  box-sizing: border-box;
}

.goodsService-help-content {
  max-width: 90%;
  margin-bottom: 1.25rem;
  font-size: 1.4rem;
  text-align: left;
}

.goodsService-help-content h2 {
  font-size: 2.6rem;
  color: white;
  text-align: center;
}

.goodsService-help-buttons {
  display: flex;
  justify-content: center;
  /* Center buttons horizontally */
  gap: 0.625rem;
  /* Space between buttons */
}

.goodsService-help-button {
  font-family: 'Roboto Serif', serif;
  font-size: 1.2rem;
  background-color: white;
  color: black;
  border: 0.06rem solid black;
  border-radius: 0.2rem;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.goodsService-help-button:hover {
  transform: scale(1.1);
}

.specimens-help-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 80vh;
  /* background-color: rgb(46, 89, 166); */
  background-color: rgb(13, 110, 253);
  z-index: 1050;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border-radius: 0.625rem;
  text-align: center;
  color: white;
  font-family: 'Roboto Serif', serif;
  padding: 1.25rem;
  box-sizing: border-box;
  border: 0.125rem solid cyan;
}

.specimens-help-content {
  margin-bottom: 1.25rem;
  font-size: 1.4rem;
  text-align: left;
  width: 90%;
  overflow-y: auto;
  text-align: left;
  padding: 1rem;
}

.specimens-help-content h2 {
  font-size: 2rem;
  color: white;
  text-align: center;
  margin-bottom: 1rem;
}

.specimens-help-buttons {
  display: flex;
  justify-content: center;
  gap: 0.625rem;
}

.specimens-help-button {
  font-family: 'Roboto Serif', serif;
  font-size: 1.2rem;
  background-color: white;
  color: black;
  border: 0.06rem solid black;
  border-radius: 0.2rem;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
}

.specimens-help-button:hover {
  transform: scale(1.1);
  background-color: #f0f0f0;
}

.specimen-sample-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1.25rem;
  margin-top: 1.25rem;
}

.specimen-sample {
  flex-basis: calc(33.333% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1rem;
}

.specimen-sample img {
  width: 100%;
  max-width: 9.4rem;
  border: 0.06rem solid black;
  margin-bottom: 0.625rem;
}

.specimen-sample img:hover {
  cursor: pointer;
}

.specimen-sample label {
  font-size: 1rem;
  color: white;
}

.image-enlarge-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  cursor: pointer;
}

.formFinal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: auto;
  border-radius: 1.875rem;
  text-align: left;
  color: black;
  font-family: 'Roboto Serif', serif;
  border: 0.3rem solid rgb(46, 89, 166);
  box-shadow: 0 0.125rem 0.125rem 0.125rem #00000040;
  padding: 1.25rem;
}

.formFinal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: auto;
  border-radius: 1.875rem;
  text-align: left;
  color: black;
  font-family: 'Roboto Serif', serif;
  border: 0.3rem solid rgb(46, 89, 166);
  box-shadow: 0 0.125rem 0.125rem 0.125rem #00000040;
  padding: 1.25rem;
}

.formFinal label {
  width: 9.375rem;
  display: inline-block;
}

.formFinal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formFinal-header img {
  max-width: 12.5rem;
  height: auto;
  margin-bottom: 0.625rem;
}

.formFinal-header-contact {
  display: flex;
  font-size: 0.9rem;
  justify-content: space-evenly;
  width: 50%;
  padding: 0 1.25rem;
  color: rgb(46, 89, 166);
}

.formFinal-container h2 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 3.125rem;
  text-align: left;
  margin-left: 2%;
}

.formFinal-body {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 3%;
  width: 90%;
}

.formFinal-body h2 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.formFinal-contact {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 3%;
}

.formFinal-contact h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 0;
  text-decoration: underline;
}

.formFinal-contact label {
  margin-right: 0.3rem;
}

.formFinal-owner {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 3%;
}

.formFinal-owner h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 0;
  text-decoration: underline;
}

.formFinal-owner label {
  margin-right: 0.3rem;
}

.formFinal-mark {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 3%;
}

.formFinal-mark h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 0;
  text-decoration: underline;
}

.formFinal-mark label {
  margin-right: 0.3rem;
}

.formFinal-gs {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 3%;
}

.formFinal-gs h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 0;
  text-decoration: underline;
}

.formFinal-gs label {
  margin-right: 0.3rem;
}

.formFinal-gs ol {
  padding-left: 0;
  margin-left: 1%;
}

.formFinal-gs li {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 0;
  list-style: decimal;
  list-style-position: outside;
  padding-left: 0;
  margin-left: 0;
}

/* .invalid-field {
  border-bottom: 0.1rem solid red;
} */

/* .invalid-select {
  padding: 0.3rem 0.625rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  font-family: 'Roboto Serif', serif;
  margin-left: 0.625rem;
  background-color: white;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  border: 0.1rem solid red;
} */

.invalid-field {
  border: 0.1rem solid red;
}

.invalid-select {
  padding: 0.3rem 0.625rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  font-family: 'Roboto Serif', serif;
  margin-left: 0.625rem;
  background-color: white;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  border: 0.1rem solid red;
}

.invalid-select:hover {
  padding: 0.3rem 0.625rem;
  border: 0.1rem solid red;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  font-family: 'Roboto Serif', serif;
  margin-left: 0.625rem;
  background-color: rgb(248, 245, 245);
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
}

.invalid-input {
  border: 0.1rem solid red;
}

.invalid-button {
  border: 0.1rem solid red;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  margin-left: 1.6rem;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
}

.invalid-button:hover {
  border: 0.1rem solid red;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  font-family: 'Roboto Serif', serif;
  background-color: lawngreen;
  border-color: lawngreen;
  margin-left: 1.6rem;
  color: white;
  transform: scale(1.1);
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
}

.invalid-date {
  border: 0.1rem solid red;
  border-radius: 0.3rem;
  font-family: 'Roboto Serif', serif;
  background-color: white;
  margin-left: 2%;
  padding: 0.3rem;
  font-size: 1.4rem;
  font-family: 'Roboto Serif', serif;
}

.login-container {
  display: flex;
  flex-direction: column;
  width: 43.75rem;
  margin: auto;
  font-family: 'Roboto Serif';
  font-size: 1.5rem;
  margin-top: 3.125rem;
}

.login-section {
  display: flex;
  flex-direction: row;
  width: 43.75rem;
  margin: auto;
  font-family: 'Roboto Serif';
  font-size: 1.5rem;
  justify-content: flex-start;
  gap: 0.3rem;
  align-items: center;
  align-content: center;
}

.login-label {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  width: 6.25rem;
}

.login-input {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  font-family: 'Roboto Serif';
  font-size: 1.5rem;
  border: 0.06rem solid black;
  width: 31.25rem;
}

.login-buttons {
  display: flex;
  flex-direction: row;
  width: 25rem;
  margin: auto;
  font-family: 'Roboto Serif';
  font-size: 1.5rem;
  justify-content: center;
  gap: 0.3rem;
  align-items: center;
  align-content: center;
  margin-top: 1.6rem;
}

.login-button {
  background-color: rgb(236, 232, 232);
  font-family: 'Roboto Serif', serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: black;
  text-align: center;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.3rem;
  padding: 0.3rem;
  cursor: pointer;
  border: none;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
  width: 9.375rem;
}

.login-button:hover {
  background-color: rgb(80, 156, 255);
  color: white;
  transform: scale(1.2);
}

.logout-button {
  background-color: rgb(236, 232, 232);
  font-family: 'Roboto Serif', serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: black;
  text-align: center;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.3rem;
  padding: 0.3rem;
  cursor: pointer;
  border: none;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
  width: 9.375rem;
}

.logout-button:hover {
  background-color: rgb(226, 44, 74);
  color: white;
  transform: scale(1.2);
}

.form-invalid-descrip {
  font-family: 'Roboto Serif', serif;
  font-size: 1.6rem;
  color: red;
}

.contactUs-Container {
  display: flex;
  flex-direction: column;
}

.contact-us-message {
  font-family: 'Roboto Serif', serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: black;
  text-align: left !important;
  /* border: 0.06rem solid black; */
  /* width: 57.5rem; */
  height: 16rem !important;
  overflow-y: auto;
  /* box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040; */
}

.invalid-contact-us-message {
  font-family: 'Roboto Serif', serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: black;
  text-align: left;
  border: 0.1rem solid red;
  width: 57.5rem;
  height: 16rem;
  overflow-y: auto;
  box-shadow: 0 0.06rem 0.06rem 0.06rem #00000040;
}

.contact-us-status {
  font-family: 'Roboto Serif', serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: black;
  text-align: left;
  margin-left: 1rem;
}

.analysis-error {
  font-family: 'Roboto Serif', serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: red;
  text-align: left;
  margin-left: 6.25rem;
  margin-right: 6.25rem;
  text-align: center;
}

.analysis-summary {
  font-family: 'Roboto Serif', serif;
  font-weight: normal;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.analysis-summary-table {
  text-align: left;
  width: 62.5rem;
  border: none;
  margin: auto;
}

.analysis-summary-table th {
  text-align: center;
  width: 31.25rem;
  border: none;
}

.analysis-summary-table th:nth-child(2) {
  text-align: left;
  padding-left: 3.125rem;
}

.analysis-summary-table td {
  border: none;
}

.analysis-summary-table tbody tr td:nth-child(1) {
  text-align: left;
}

.analysis-summary-table tbody tr td:nth-child(2) {
  text-align: left;
  padding-left: 3.125rem;
}

.analysis-total {
  color: red;
  text-align: right;
}

.analysis-total td:nth-child(1) {
  text-align: right;
}

/* Add to App.css or a global styles file */
/* .test-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
} */

.test-modal {
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: white;
  display: block;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  text-align: center;
  color: black;
  font-family: 'Roboto Serif', serif;
  z-index: 9999;
  /* background-color: #0056b3; */
}

.test-modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.optional {
  font-size: 0.75rem;
}

.form-control {
  text-align: center;
  font-size: 1.2rem;
  height: 38px;
  padding: 6px 12px;
  color: black;
  font-family: 'Roboto Serif', serif;
}

.form-control:focus {
  border: 0.125rem solid lawngreen;
}

.row {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0.5rem;
}

.form-group {
  text-align: left;
  font-size: 1.2rem;
}

.form-group span {
  color: black;
  margin-left: 0.5rem;
}

.appTypeSelect {
  width: 100%;
  max-width: 12rem;
  text-align: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 140,0 70,70" fill="black"/></svg>') no-repeat right 10px center;
  background-size: 10px;
  padding-right: 30px;
  background-color: white;
}

.colorClaimCBLabel {
  /* white-space: nowrap; */
  align-content: center;
  margin-left: 0.5rem;
  display: inline;
}

.form-label {
  font-family: 'Roboto Serif', serif;
  color: black;
  font-size: 1.2rem;
}

.px-custom {
  padding-left: 10px;
  padding-right: 10px;
}

.card {
  border: none;
  width: 100%;
  border-radius: 0;
}

.card:nth-child(even) {
  background-color: rgb(13, 110, 253);
  color: white;
}

.card:nth-child(even) .use-label,
.card:nth-child(even) .use-group .form-text {
  color: white;
}

/* .card:nth-child(odd) {
  background-color: white;
  color:black;
} */

.card:nth-child(odd) .use-label,
.card:nth-child(odd) .use-group .form-text {
  color: black;
}

.use-info-container {
  display: flex;
  flex-direction: column;
}

.use-component {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  align-items: start;
}

.use-component label {
  padding-bottom: 0;
  margin-bottom: 0;
}

.use-component input {
  margin-left: 2rem;
}

.use-component CountryComboBox {
  margin-left: 2rem !important;
}

.specimen-input {
  display: flex;
  flex-direction: row;
  color: black;
  font-family: 'Roboto Serif', serif;
  font-size: 1.2rem;
  font-weight: 100;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}


.use-group {
  text-align: left;
  font-size: 1.2rem;
}

/* .navbar-brand {
  position: absolute;
  left: 6rem;
} */

.navbar-toggler {
  position: absolute;
}

.remaining-characters {
  font-size: 0.8rem;
}

.transparent-background {
  background-color: transparent !important;
  border: none !important;
}

.list-group-item.transparent-background,
.alert.transparent-background,
.card.transparent-background,
.card-body.transparent-background,
ul.list-group.transparent-background {
  background-color: transparent !important;
  border: none !important;
}

.loading-icon {
  width: 20px;
  height: 20px;
}

.close-engagement {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
}

.download-container {
  font-family: 'Roboto Serif', serif;
  font-size: 1.2rem;
}

.download-link {
  color: blue;
  text-decoration: underline;
  font-family: 'Roboto Serif', serif;
  font-size: 1.2rem;
}

.download-link:hover {
  cursor: pointer;
}

.oaOwnerEmail-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: 'Roboto Serif', serif;
  font-size: 1.125rem;
  margin-left: 0;
  margin-right: auto;
}

.oaOwnerEmail-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 0;
}

.oaOwnerEmail-group label {
  padding-bottom: 0;
  margin-bottom: 0;
}

.change-representation-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  /* margin-left: 5rem; */
}

.car-header {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 6pt;
  line-height: normal;
}

.car-header p {
  padding: 0;
  margin-bottom: 0.4rem;
}

.car-header p:nth-child(3) {
  margin-bottom: 0;
}

.car-instr {
  font-family: 'Times New Roman', Times, serif;
  font-size: 12pt;
}

.car-body-title {
  text-align: center;
  font-size: 14pt;
  font-weight: bold;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.car-body-title p {
  padding: 0;
  margin: 0;
}

.car-body-title p:nth-child(2) {
  font-size: 16pt;
}

.car-body {
  font-size: 12pt;
}

.car-body p:nth-child(3) {
  margin-bottom: 0;
}

.car-body p:nth-child(8) {
  margin-bottom: 0;
}

.car-body p:nth-child(10),
.car-body p:nth-child(11),
.car-body p:nth-child(12),
.car-body p:nth-child(13),
.car-body p:nth-child(14) {
  margin-bottom: 0;
}

/* .car-body ul{
  display: block !important;
  list-style-type:  disc !important;
}

.car-body li{
  list-style-type:  disc !important;

} */

.car-logo {
  margin-left: 0;
  padding: 0;
  max-width: 320px;
  height: auto;
}

.signature-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  background-color: rgb(46, 89, 166);
  z-index: 1005;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  text-align: center;
  color: white;
  font-family: 'Roboto Serif', serif;
  padding: 1.25rem;
  box-sizing: border-box;
}

.client-portal-login-button {
  font-family: 'Roboto Serif', serif;
  background-color: rgb(46, 89, 166);
  color: white;
}

.client-portal-login-button-loading {
  font-family: 'Roboto Serif', serif;
  background-color: gray;
  color: black;
  cursor: wait;
}

.client-portal-container {
  font-family: 'Roboto Serif', serif;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 45vh;
  margin-bottom: 3rem;
}

.client-portal_results-table {
  font-size: 1.4rem;
  width: 90vw;
  table-layout: auto;
  background-color: transparent !important;
  margin-top: 3rem;
}

.client-portal_results-table.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-striped-bg: rgba(0, 98, 255, 0.1);
  --bs-table-striped-color: inherit;
}

.client-portal_results-table.table-striped>tbody>tr:hover>* {
  background-color: rgb(46, 89, 166) !important;
  color: white;
  cursor: pointer;
}

.client-portal_results-table.table-striped>tbody>tr:hover>.uspto-link a {
  color: cyan !important;
}

.client-portal_results-table th {
  font-weight: bold;
  font-size: 1.6rem;
}

.client-portal_results-table th,
.client-portal_results-table td {
  padding: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-portal_results-table th.description,
.client-portal_results-table td.description {
  white-space: normal;
  width: 100%;
  overflow-wrap: break-word;
}

.client-portal_results-table th.file-number,
.client-portal_results-table td.file-number,
.client-portal_results-table th.reference,
.client-portal_results-table td.reference,
.client-portal_results-table th.serial-number,
.client-portal_results-table td.serial-number,
.client-portal_results-table th.filing-date,
.client-portal_results-table td.filing-date,
.client-portal_results-table th.file-status,
.client-portal_results-table td.file-status,
.client-portal_results-table th.uspto-link,
.client-portal_results-table td.uspto-link {
  width: 1%;
}

.client-portal_results-table th {
  text-align: center;
}

.client-portal_results-table td {
  text-align: left;
}

.user-registration-container {
  font-family: 'Roboto Serif', serif;
  font-size: 1.4rem;
  color: white;
  /* min-height: 52vh; */
  margin: 2rem auto;
}

.user-registration-label {
  font-family: 'Roboto Serif', serif;
  font-size: 1.4rem;
  color: white;
}

.user-registration-login-button {
  background-color: white;
  color: rgb(46, 89, 166);
  width: 8rem;
  transition: transform 0.1s ease-in-out;
  font-size: 1.2rem;
}

.user-registration-login-button:hover {
  transform: scale(1.2);
  background-color: green;
}

.user-registration-cancel-button {
  background-color: white;
  color: rgb(46, 89, 166);
}

.user-registration-cancel-button:hover {
  background-color: rgb(201, 60, 60);
  color: black;
}

.user-registration-link-container {
  width: 50%;
  margin: 2rem auto 0 auto;
}

.user-registration-link {
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.user-registration-link:hover {
  transform: scale(1.15);
  color: cyan;
}

.user-registration-login-button-container {
  margin: auto;
  justify-content: center;
}

.signout {
  font-family: 'Roboto Serif', serif;
  font-size: 1.6rem;
  color: rgb(46, 89, 166);
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.signout:hover {
  transform: scale(1.15);
}

.forgot-password {
  margin-top: 2rem;
  color: rgb(46, 89, 166);
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.forgot-password:hover {
  transform: scale(1.15);
}

.client-file-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-file-modal {
  font-family: 'Roboto Serif', serif;
  font-size: 1.6rem;
  text-align: left;
  color: rgb(46, 89, 166);
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  position: relative;
}

.close-modal-btn {
  position: absolute;
  top: 0;
  right: 10px;
  background: none;
  border: none;
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  z-index: 1050;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.close-modal-btn:hover {
  color: #f00;
  background-color: transparent;
  transform: scale(1.5);
}


.client-file-modal .nav-tabs .nav-link {
  font-size: 1.8rem;
}

.custom-modal-width .modal-dialog {
  width: 80vw;
  max-width: 80vw;
  margin: 1.75rem auto;
}

.custom-modal-width .modal-content {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  font-family: 'Roboto Serif', serif;
  font-size: 1.6rem;
}

.goods-block {
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.goods-block h5 {
  margin-bottom: 10px;
  font-weight: bold;
}

.goods-block p {
  margin-bottom: 5px;
}

.appUSPTOLink {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.appUSPTOLink:hover {
  transform: scale(1.15);
  color: cyan;
}

.client-file-overview {
  margin: 1.5rem 0;
  line-height: 2;
  display: flex;
  flex-direction: column;
}

.client-file-item {
  display: flex;
  flex-direction: row;
}

.client-file-item strong {
  width: 325px;
  margin-right: 1rem;
}

.client-file-file-container {
  margin-top: 2rem;
  line-height: 3;
}

.client-file-file-container li {
  width: 500px;
  white-space: normal;
  word-wrap: break-word;
  margin-right: 10rem;
  font-size: 1.2rem;
}

.client-file-file {
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
  color: black;
  text-decoration: none;
}

.client-file-file:hover {
  transform: scale(1.15);
  color: rgb(13, 110, 253);
}

.client-file-deadlines-overview {
  margin: 1.5rem 0;
  line-height: 2;
  display: flex;
  flex-direction: column;
}

.client-file-deadline {
  display: flex;
  flex-direction: row;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.client-file-deadline:hover {
  cursor: pointer;
  transform: scale(1.05);
  color: rgb(13, 110, 253);
}

.client-file-no-deadlines {
  width: 100%;
  font-size: 1.8rem;
  font-weight: bold;
  font-style: italic;
  margin: 1.5rem 0;
}

.client-file-deadline-date {
  width: 250px;
  /* margin-right: 1rem; */
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.client-file-deadline-event {
  white-space: normal;
  word-wrap: break-word;
  width: auto;
  transition: transform 0.1s ease-in-out;
  transform: scale(1);
}

.authorizeSOU-classBlock-header {
  background-color: #0056b3;
}

.authorizeSOU-classBlock-header h2 {
  font-size: 1.4rem;
  font-weight: normal;
  font-style: normal;
  color: white;
  text-align: left;
}

.authorizeSOU-classBlock-content {
  background-color: #0056b3;
  color: white;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
}

.file-preview img {
  max-height: 150px;
  width: auto;
  margin-bottom: 0.5rem;
}

.hidden-file-input {
  display: none;
}

.custom-file-input-wrapper {
  position: relative;
}

.custom-file-input-wrapper Button {
  background-color: white;
  color: black;
  font-size: 1.2rem;
}

.cost-summary {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #dee2e6;
  background-color: #f8f9fa;
}

.cost-summary h3 {
  margin-bottom: 1rem;
}

.cost-summary p,
.cost-summary h4 {
  margin: 0.5rem 0;
}

.enabledSubmitButton,
.disabledSubmitButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.25rem;
  display: inline-block;
  text-align: center;
}

.enabledSubmitButton {
  background-color: rgb(80, 156, 255);
  color: white;
  transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
}

.enabledSubmitButton:hover {
  background-color: rgb(70, 146, 245);
  cursor: pointer;
  transform: scale(1.05);
}

.disabledSubmitButton {
  background-color: lightgray;
  color: #000;
}

.disabledButtonWrapper {
  cursor: not-allowed;
}

.disabledButtonWrapper .disabledSubmitButton {
  background-color: lightgray;
  color: black;
}

.authorizeOAR-rejectionType-header {
  background-color: #0056b3;
  font-size: 1.4rem;
  font-weight: normal;
  font-style: normal;
  color: white;
  text-align: left;
}

.authorizeOAR-rejectionType-content {
  background-color: #0056b3;
  color: white;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
}

.response-options {
  text-align: left;
  margin-left: 2rem;
}

.response-options input:hover {
  cursor: pointer;
}

.response-options label:hover {
  cursor: pointer;
}

.response-options input {
  width: 3rem;
}

.response-options h6 {
  font-size: 1.4rem;
  text-decoration: underline;
}

.response-options ul {
  font-size: 1.2rem;
}

.response-options li {
  list-style: disc;
  font-size: 1.2rem;
}

.payment-form-container {
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.payment-form-container h2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.payment-form-container p {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-bottom: 1.5rem;
}

.payment-form-container .StripeElement {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.payment-form-container button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s;
}

.payment-form-container button:hover {
  background-color: #0056b3;
}

.payment-form-container .message {
  font-size: 1rem;
  color: #e74c3c;
  margin-top: 1rem;
  text-align: center;
}

.payment-modal-container {
  /*position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  align-items: center;
  justify-content: center;*/
  z-index: 99999;
  /* Ensure it's on top of everything */
}

.payment-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 10000;
  /* Ensures content is above overlay */
}

.search-option-container {
  max-width: 50rem;
  margin: auto;
}

.option-box {
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: white;
  margin-bottom: 4rem;
  margin-top: 4rem;
  width: 20rem;
}

.option-box h5 {
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.option-box h6 {
  font-weight: bold;
  font-size: 1.4rem;
  color: rgb(13, 110, 253);
}

.option-box:hover {
  transform: scale(1.1);
}

.option-box.selected {
  border-color: #0044cc;
  background-color: rgba(13, 110, 253, 0.075);
  box-shadow: 0 4px 8px rgba(0, 68, 204, 0.2);
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .option-box {
    min-height: 150px;
  }
}

.fee-display {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.disclaimer-container {
  text-align: center;
  width: 100%;
}

.disclaimer {
  font-size: 0.9rem;
}

.atty-search-input {
  height: 40px !important;
  padding: 6px 12px;
  line-height: 1.5;
}

.atty-search-button {
  height: 40px !important;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border-radius: 5px;
  transition: transform 0.1s ease-in-out;
}

.atty-search-button:hover {
  transform: scale(1.1);
}

.col-auto.d-flex {
  align-items: center !important;
}

.atty-review-loading {
  font-family: 'Roboto Serif', serif;
  font-size: 1.6rem;
  font-style: italic;
}

.atty-review-description-flag-free-form {
  font-family: 'Roboto Serif', serif;
  font-size: 0.9rem;
  font-style: italic;
  color: red;
  white-space: nowrap;
  margin-left: 0.5rem;
}

.atty-review-description-flag-teasPlus {
  font-family: 'Roboto Serif', serif;
  font-size: 0.9rem;
  font-style: italic;
  color: rgb(86, 216, 0);
  white-space: nowrap;
  margin-left: 0.5rem;
}

.atty-review-class-description {
  width: 30rem;
  text-align: left;
}

.atty-review-class-label {
  width: 40rem;
}

.password-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.password-field {
  width: 100%;
  padding-right: 2.5rem;
}

.password-toggle {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #6c757d;
}

.password-toggle:hover {
  color: #343a40;
}

.reverse-gradient {
  /* background: linear-gradient(to bottom, rgba(46, 89, 166, 0.25), #ffffff); */
}

.intro-section {
  /* background-color: rgba(238, 231, 231, 0.496); */
  /* background: linear-gradient(to top, rgba(46, 89, 166, 0.25), #ffffff); */
  text-shadow: 0.5px 0.5px 2px rgb(255, 255, 255);
  font-size: 1.8rem !important;
  font-family: 'Cormorant Garamond, serif';
  font-size: 1.2rem;
  font-weight: 400;
}

.intro-section-brief {
  font-size: 2rem;
  font-weight: 200;
  color: rgb(46, 89, 166);
  margin-bottom: 3rem;
}

.intro-section-descrip-1 {
  /* style={{marginLeft:'10%', marginRight:'10%', marginBottom:'1%', color:'rgb(46, 89, 166)', textAlign: 'left'}} */
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1%;
  color: rgb(46, 89, 166);
  text-align: left;
}

.intro-section-descrip-2 {
  /* style={{marginLeft:'10%', marginRight:'10%', marginBottom:'3%', color:'rgb(46, 89, 166)', textAlign: 'left'}} */
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 3%;
  color: rgb(46, 89, 166);
  text-align: left;
}

.email-verified-landing {
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
  padding: 3rem 1rem;
  font-family: 'Roboto', serif;
  text-align: center;
}

.resend-verification-email-button {
  background-color: white;
  color: rgb(46, 89, 166);
  font-family: 'Roboto', serif;
  font-size: 1.2rem;
  transition: transform 0.1s ease-in-out;
}

.resend-verification-email-button:hover {
  transform: scale(1.1);
  background-color: rgba(252, 253, 255, 0.4);
}

.custom-dropdown {
  position: relative;
}

.custom-dropdown-menu {
  position: absolute;
  top: 2.5rem;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  width: max-content;
  min-width: 12rem;
  z-index: 999;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  font-size: 1rem;
  margin-top: calc(100% + 0.25rem);
}

.custom-dropdown .nav-link {
  cursor: pointer;
  position: relative;
  z-index: 10000;
  font-size: 2rem;
  font-weight: 700;
  background-color: transparent;
}

.custom-dropdown-menu li {
  list-style: none;
}

.custom-dropdown-menu li a {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(46, 89, 166);
  text-decoration: none;
  font-size: 1rem;
}

.custom-dropdown-menu li a:hover {
  background-color: #f0f0f0;
}

.refund-policy {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
  font-family: 'Roboto Serif', serif;
  font-size: 1.2rem;
}

.refund-policy h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2%;
  font-weight: bold;
}

.refund-policy p {
  font-size: 1.4rem !important;
}

.refund-policy ol, li {
  font-size: 1.4rem;
}

.refund-policy h3 {
  font-size: 1.8rem;
  text-decoration: underline;
  font-weight: bold;
}

.privacy-policy {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
  font-family: 'Roboto Serif', serif;
  font-size: 1.2rem;
}

.privacy-policy h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2%;
  font-weight: bold;
}

.privacy-policy p {
  font-size: 1.4rem;
}

.privacy-policy ol {
  padding-left: 2rem;
}

.privacy-policy ol li p {
  font-size: 1.4rem !important;
  text-align: left;
  text-indent: none;
}

.privacy-policy ol li {
  font-size: 1.4rem;
  list-style: decimal;
  list-style-position: outside;
  padding-left: 2rem;
}

.privacy-policy ol li::marker {
  padding-right: 2rem;
}

.privacy-policy ol li ul{
  padding-left: 4rem;
}

.privacy-policy ol li ul li{
  list-style: disc;
}

.privacy-policy h3 {
  font-size: 1.8rem;
  text-decoration: underline;
  font-weight: bold;
}

.admin-items {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 0.6rem;
  padding-right: 0;
  margin-top: 0;
}

.admin-item-link {
  text-decoration: none;
  color: #ffffff;
  font-family: "Roboto Serif", serif;
  font-size: 1rem;
  font-weight: 400;
}

.admin-item-link:hover {
  color: #00AFEF;
  transition: color 0.3s;
}

.reasons-why-us {
  color: rgb(46, 89, 166);
  width: 100%;
  justify-content: center;
  text-align: left;
  margin-top: 2rem;
}

.reasons-why-us ul{
  margin-left: 15%;
}

.reasons-why-us ul li {
  font-family: "Roboto Serif", serif;
  font-size: 2.2rem;
  line-height: 2;
  font-weight: 300;
  list-style: none;
  position: relative; /* Needed for absolute positioning of ::before */
  padding-left: 4rem; /* Add padding to make space for the checkmark */
}

.reasons-why-us ul li::before {
  content: "\2714";
  position: absolute;
  left: 0;
  top: 0.75rem; /* Slight vertical alignment tweak */
  color: #1e88e5;
  font-size: 2.5rem; /* Make it a bit larger to match text size */
  line-height: 1;
}


.overall-gradient {
  /* background: linear-gradient(to top, rgba(46, 89, 166, 0.25), #ffffff); */
  background: radial-gradient(circle at center, rgba(46, 89, 166, 0.15), #ffffff);
}

.section-divider {
  border: none;
  border-top: 2px solid rgb(46, 89, 166); /* Or any brand color you like */
  margin: 3rem auto;
  width: 80%; /* You can adjust this as needed */
}
